import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OnyxPaginated, OnyxPagination } from '@onyx/angular';
import { Observable, of, Subject, tap } from 'rxjs';
import { ApiService } from '../../../../common/services/api.service';
import { DelegationDto } from '../../delegations-form/delegations-form.component';
import { DelegationStatus } from '../enums/delegation-status';
import { Delegation } from '../interfaces/delegation';

@Injectable({
  providedIn: 'root',
})
export class DelegationsService extends ApiService {
  private _reload$ = new Subject<void>();
  public get reload$() {
    return this._reload$.asObservable();
  }

  constructor(protected override http: HttpClient) {
    super(http);
  }

  public listDelegations(
    params: {
      category: DelegationStatus;
      status: DelegationStatus[];
      hasDoubleCrew: boolean;
      showAssignedOnly: boolean;
    } & OnyxPagination,
  ): Observable<OnyxPaginated<Delegation>> {
    return of({
      items: [],
      totalItems: 0,
      page: 1,
      limit: params.limit,
    });
    // return this.get('/delegations', {
    //   params: {
    //     category: params.category,
    //     'status[]': params.status ?? [],
    //     hasDoubleCrew: params.hasDoubleCrew,
    //     showAssignedOnly: params.showAssignedOnly,
    //     page: params.page,
    //     limit: params.limit,
    //   },
    // });
  }

  public getDelegation(uuid: string): Observable<Delegation> {
    return this.get(`/delegations/${uuid}`);
  }

  public addDelegation(delegation: DelegationDto): Observable<void> {
    return this.post<void>('/delegations', delegation).pipe(
      tap(() => this._reload$.next()),
    );
  }

  public editDelegation(
    uuid: string,
    delegation: DelegationDto,
  ): Observable<void> {
    return this.put<void>(`/delegations/${uuid}`, delegation).pipe(
      tap(() => this._reload$.next()),
    );
  }
}
