import { Routes } from '@angular/router';
import { OnyxFormMode } from '@onyx/angular';
import { addDelegationResolver } from './common/resolvers/add-delegation.resolver';
import { editDelegationResolver } from './common/resolvers/edit-delegation.resolver';

export enum DelegationsRoute {
  DELEGATIONS_LIST = '/delegations',
  ADD_DELEGATION = '/delegations/add',
  EDIT_DELEGATION = '/delegations/:uuid/edit',
}

export const DELEGATIONS_ROUTES: Routes = [
  {
    path: '',
    pathMatch: 'prefix',
    loadComponent: () =>
      import('./delegations-list/delegations-list.component').then(
        (m) => m.DelegationsListComponent,
      ),
  },
  {
    path: 'add',
    loadComponent: () =>
      import('./delegations-form/delegations-form.component').then(
        (m) => m.DelegationsFormComponent,
      ),
    data: { mode: OnyxFormMode.ADD },
    resolve: { delegation: addDelegationResolver },
  },
  {
    path: ':uuid/edit',
    loadComponent: () =>
      import('./delegations-form/delegations-form.component').then(
        (m) => m.DelegationsFormComponent,
      ),
    data: { mode: OnyxFormMode.EDIT },
    resolve: { delegation: editDelegationResolver },
  },
] as const;
