<onyx-modal
  type="right"
  [heading]="driver.driverData.firstName + ' ' + driver.driverData.lastName"
  [roles]="[{ value: 'labels.driver', color: 'blue' }]"
  size="s"
  (closeModal)="dialogRef.close()"
>
  <onyx-icon headingLeftOptions name="tacho-other-work" [size]="20"></onyx-icon>

  <ng-container rightOptions>
    <onyx-icon-button
      color="black"
      type="transparent"
      size="m"
      [onyxDropdownOptions]="DROPDOWN_OPTIONS"
    >
      <onyx-icon name="options-horizontal" [size]="16"></onyx-icon>
    </onyx-icon-button>

    @if (driver.driverData.workPhone) {
      <onyx-icon-button
        color="blue"
        type="primary"
        size="m"
        [circle]="true"
        (click)="ActionHelper.makeCall(driver.driverData.workPhone)"
      >
        <onyx-icon name="telephone" [size]="16"></onyx-icon>
      </onyx-icon-button>
    }
  </ng-container>

  <div class="container">
    <!-- TEMP: Hold by backend -->
    <onyx-information-row [label]="'labels.vehicle' | translate" size="s">
      <!-- <div class="vehicle">
        @let vehicle = driver.assignedVehicle.generalInformation;
        <onyx-icon [name]="vehicle.category" [size]="18"></onyx-icon>

        {{ vehicle | vehicleIdentifier }}
      </div> -->
      N/A
    </onyx-information-row>

    <onyx-information-row [label]="'labels.semiTrailer' | translate" size="s">
      N/A
    </onyx-information-row>

    <onyx-information-row
      class="order"
      [label]="'labels.order' | translate"
      size="s"
    >
      N/A
    </onyx-information-row>

    @let driverData = driver.driverData;

    @if (driver.driverData.workPhone) {
      <onyx-information-row [label]="'labels.workPhone' | translate" size="s">
        {{ driver.driverData.workPhone | onyxPhone }}

        <div class="copy">
          <onyx-icon-button
            type="transparent"
            size="s"
            color="black"
            (click)="copyPhone(driver.driverData.workPhone!)"
          >
            <onyx-icon name="copy" [size]="16"></onyx-icon>
          </onyx-icon-button>
        </div>
      </onyx-information-row>
    }

    @if (driverData.privateEmail; as email) {
      <onyx-information-row [label]="'labels.email' | translate" size="s">
        {{ email }}

        <div class="copy">
          <onyx-icon-button
            type="transparent"
            size="s"
            color="black"
            (click)="copy(email)"
          >
            <onyx-icon name="copy" [size]="16"></onyx-icon>
          </onyx-icon-button>
        </div>
      </onyx-information-row>
    }

    <div class="chips">
      <onyx-chips
        [chips]="GROUP_CHIPS"
        type="secondary"
        [single]="true"
        [wrap]="true"
        [(activeValues)]="group"
      ></onyx-chips>
    </div>

    @switch (group()[0]) {
      @case (DriverModalOption.WORK_TIME) {
        N/A
      }
      @case (DriverModalOption.DELEGATION) {
        N/A
      }
      @case (DriverModalOption.STATISTICS) {
        N/A
      }
      @case (DriverModalOption.UNAVAILABILITIES) {
        N/A
      }
      @case (DriverModalOption.DRIVER_DATA) {
        <app-driver-modal-data [driver]="driver"></app-driver-modal-data>
      }
      @case (DriverModalOption.DOCUMENTS) {
        <app-driver-modal-documents
          [driver]="driver"
        ></app-driver-modal-documents>
      }
      @case (DriverModalOption.NOTE) {
        <p class="note">
          {{ driver.note || '-' }}
        </p>
      }
    }
  </div>
</onyx-modal>
